import { DeleteOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Input as AntInput, Button, Collapse, message, Modal, Radio } from 'antd'
import axios from 'axios'
import { Checkbox, Input, InputNumber, Select } from 'formik-antd'
import React, { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'

import ShopifyProducts from './ShopifyProducts'
import { API_URL } from '../../../../constants'
import FormItem from '../../../utility/FormItem'

export const CampaignCompensation = ({ values, setFieldValue, campaign }) => {
  const queryCache = useQueryClient()
  const [numOptInVariables, setNumOptInVariables] = useState(undefined)
  const [variationToDelete, setVariationToDelete] = useState(null)
  const [optionToDelete, setOptionToDelete] = useState(null)
  const [deletingVariation, setDeletingVariation] = useState(null)
  const [deletingOption, setDeletingOption] = useState(null)

  const [productValueType, setProductValueType] = useState(values?.minValue ? 'ranged' : 'fixed')

  const { data: productLists, status: productListsStatus } = useQuery(
    ['product-lists', campaign.brandId],
    () => axios.get(`${API_URL}/brand/${campaign.brandId}/product-list`).then(res => res.data.lists)
  )

  const handleDeleteVariation = async variable => {
    setDeletingVariation(variable.id)

    try {
      await axios.delete(`${API_URL}/admin-dashboard/variable/${variable.id}`)
      queryCache.invalidateQueries(['campaign', campaign.id])
      message.success('Deleted Successfully')
      // remove the variable from the form values
      setFieldValue(
        'variables',
        values.variables.filter(v => v.id !== variable.id)
      )
    } catch (err) {
      message.error('Error deleting variation')
    }
  }

  const handleDeleteOption = async option => {
    setDeletingOption(option.id)

    try {
      await axios.delete(`${API_URL}/admin-dashboard/option/${option.id}`)
      queryCache.invalidateQueries(['campaign', campaign.id])
      message.success('Deleted Successfully')
      // remove the option from the form values
      setFieldValue(
        'variables',
        values.variables.map(v =>
          v.options.filter(o => o.id !== option.id).length > 0
            ? {
                ...v,
                options: v.options.filter(o => o.id !== option.id),
              }
            : v
        )
      )
    } catch (err) {
      message.error('Error deleting variation option')
    }
  }

  return (
    <Wrapper>
      <FormItem>
        <Checkbox name='paidCampaign'>Paid Campaign</Checkbox>
      </FormItem>

      {values.paidCampaign && (
        <div className='section-wrapper'>
          <FormItem label='Payment'>
            <Radio.Group
              name='paymentType'
              value={values.paymentType}
              onChange={e => {
                if (e.target.value === 'fixed') {
                  // minPaidAmount should only be set for ranged payments
                  setFieldValue('minPaidAmount', null)
                }
                if (e.target.value === 'ranged') {
                  // enableNegotiation setting only meant for fixed payments (ranged payments are negotiated by default)
                  setFieldValue('enableNegotiation', false)
                }
                setFieldValue('paymentType', e.target.value)
              }}>
              <Radio value='fixed'>Fixed</Radio>
              <Radio value='ranged'>Range</Radio>
            </Radio.Group>

            {values.paymentType && (
              <div className='form-row' style={{ marginTop: '10px' }}>
                {values.paymentType === 'ranged' && (
                  <>
                    <InputNumber
                      // if this has a value, the payment type is ranged and maxPaidAmount should be required
                      name='minPaidAmount'
                      disabled={values.paymentType === 'fixed'}
                      formatter={value =>
                        value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      } // format to currency
                      parser={value => value.replace(/\$\s?|(,*)/g, '')} // remove $ and commas for backend
                      placeholder='Min Amount'
                      onChange={() => {
                        setFieldValue('enableNegotiation', false)
                      }}
                    />
                    -
                  </>
                )}
                <InputNumber
                  name='maxPaidAmount'
                  formatter={value => value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  placeholder='Max Amount'
                />
              </div>
            )}
          </FormItem>

          {values.paymentType !== 'ranged' && (
            <FormItem>
              <Checkbox
                name='enableNegotiation'
                disabled={values.minPaidAmount}
                checked={values.minPaidAmount ? false : values.enableNegotiation}>
                Enable payment negotiation
              </Checkbox>
            </FormItem>
          )}

          <FormItem>
            <Checkbox name='disablePayments'>Disable automatic payments</Checkbox>
          </FormItem>
        </div>
      )}

      <div className='section-wrapper'>
        <FormItem label='Product / Experience Value'>
          <Radio.Group
            name='productValueType'
            defaultValue={values?.minValue ? 'ranged' : 'fixed'}
            onChange={e => {
              setProductValueType(e.target.value)
              setFieldValue('minValue', null)
            }}>
            <Radio value='fixed'>Fixed</Radio>
            <Radio value='ranged'>Range</Radio>
          </Radio.Group>

          <div className='form-row' style={{ marginTop: '10px' }}>
            {values?.minValue || productValueType === 'ranged' ? (
              <>
                <InputNumber
                  name='minValue'
                  placeholder='Min'
                  controls={false}
                  formatter={value => value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  min={0}
                  max={values?.value - 1}
                  onChange={e => setFieldValue('minValue', e)}
                />
                -
                <InputNumber
                  name='value'
                  placeholder='Max'
                  controls={false}
                  min={values?.minValue + 1 || 0}
                  max={999999}
                  formatter={value => value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              </>
            ) : (
              <InputNumber
                name='value'
                formatter={value => value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            )}
          </div>
        </FormItem>

        <FormItem label='Credit Card Hold'>
          <InputNumber
            name='holdCost'
            formatter={value => value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        </FormItem>
      </div>

      <div className='section-wrapper'>
        <ShopifyProducts brandId={campaign.brandId} campaignId={values.id} />
        <FormItem label='Max Products'>
          <InputNumber name='maxShopifyProducts' />
        </FormItem>
      </div>

      <div className='section-wrapper'>
        <FormItem label='Product Description'>
          <Input.TextArea name='productDescription' autoSize={{ minRows: 4, maxRows: 10 }} />
        </FormItem>

        <FormItem label='Fulfillment Type'>
          <Input disabled name='fulfillmentType' />
        </FormItem>

        {values.fulfillmentTYpe === 'other' && (
          <FormItem label='Fulfillment Type Other'>
            <Input disabled name='fulfillmentTypeOther' />
          </FormItem>
        )}

        <FormItem label='Product List'>
          <Select
            name='productListId'
            placeholder='Select'
            style={{ width: '100%', marginBottom: '10px' }}
            allowClear
            loading={productListsStatus === 'loading'}>
            {productLists?.map(list => (
              <Select.Option key={list.id} value={list.id}>
                {list.title}
                {list.deactivated && ' (Deactivated)'}
              </Select.Option>
            ))}
          </Select>
        </FormItem>

        <h3>Variations</h3>
        <Collapse className='variation-collapse'>
          {values.variables.map((variable, index) => (
            <>
              <Collapse.Panel
                className='variation'
                key={`${index}-${variable.id}`}
                accordion
                header={variable.title || 'Untitled'}>
                <FormItem label='Title'>
                  <Button
                    type='link'
                    danger
                    className='variations-delete-btn-wrapper'
                    icon={<DeleteOutlined />}
                    loading={deletingVariation === variable.id}
                    onClick={() => {
                      if (variable.optInVariables.length > 0) {
                        setNumOptInVariables(variable.optInVariables.length)
                        setVariationToDelete(variable)
                      } else handleDeleteVariation(variable)
                    }}
                  />

                  <AntInput
                    value={variable.title}
                    onChange={e => {
                      setFieldValue('variables', [
                        ...values.variables.map(v =>
                          v.id !== variable.id
                            ? v
                            : {
                                ...variable,
                                title: e.target.value,
                              }
                        ),
                      ])
                    }}
                  />
                </FormItem>

                <h4>Options</h4>
                {variable.options.map((option, index) => (
                  <div className='options-wrapper' key={`${index}-${option.id}`}>
                    <FormItem label='Label'>
                      <Button
                        type='link'
                        danger
                        className='options-delete-btn'
                        icon={<MinusCircleOutlined />}
                        loading={deletingOption === option.id}
                        onClick={() => {
                          if (variable.optInVariables.length > 0) {
                            setNumOptInVariables(variable.optInVariables.length)
                            setOptionToDelete(option)
                          } else handleDeleteOption(option)
                        }}
                      />
                      <AntInput
                        value={option.title}
                        className='label-input'
                        onChange={e => {
                          setFieldValue('variables', [
                            ...values.variables.map(v =>
                              v.id !== variable.id
                                ? v
                                : {
                                    ...variable,
                                    options: [
                                      ...variable.options.map(o =>
                                        o.id !== option.id
                                          ? o
                                          : {
                                              ...option,
                                              title: e.target.value,
                                            }
                                      ),
                                    ],
                                  }
                            ),
                          ])
                        }}
                      />
                    </FormItem>
                    <FormItem label='Description'>
                      <AntInput.TextArea
                        value={option.description}
                        onChange={e => {
                          setFieldValue('variables', [
                            ...values.variables.map(v =>
                              v.id !== variable.id
                                ? v
                                : {
                                    ...variable,
                                    options: [
                                      ...variable.options.map(o =>
                                        o.id !== option.id
                                          ? o
                                          : {
                                              ...option,
                                              description: e.target.value,
                                            }
                                      ),
                                    ],
                                  }
                            ),
                          ])
                        }}
                      />
                    </FormItem>
                  </div>
                ))}
                <Button
                  type='secondary'
                  onClick={() => {
                    setFieldValue('variables', [
                      ...values.variables.map(v =>
                        v.id !== variable.id
                          ? v
                          : {
                              ...variable,
                              options: [
                                ...variable.options,
                                {
                                  id: `temp-${variable.options.length}`,
                                  title: '',
                                  description: '',
                                },
                              ],
                            }
                      ),
                    ])
                  }}>
                  <PlusOutlined /> Add Option
                </Button>
              </Collapse.Panel>
            </>
          ))}
        </Collapse>

        <Button
          type='primary'
          style={{ marginTop: '20px' }}
          onClick={() => {
            setFieldValue('variables', [
              ...values.variables,
              {
                id: `temp-${values.variables.length}`,
                title: '',
                options: [],
              },
            ])
          }}>
          <PlusOutlined /> Add Variation
        </Button>

        <Modal
          title={`Delete ${optionToDelete ? 'Option' : 'Variation'}`}
          open={variationToDelete || optionToDelete}
          okButtonProps={{ danger: true }}
          okText='Delete'
          onOk={() => {
            setNumOptInVariables(undefined)
            if (optionToDelete) {
              handleDeleteOption(optionToDelete)
              setOptionToDelete(null)
            } else {
              handleDeleteVariation(variationToDelete)
              setVariationToDelete(null)
            }
          }}
          onCancel={() => {
            setNumOptInVariables(undefined)
            setOptionToDelete(null)
            setVariationToDelete(null)
          }}>
          <p>
            This {optionToDelete ? 'option' : 'variation'} has <b>{numOptInVariables}</b> opt-ins
            associated with it. Are you sure you want to delete it?
          </p>
        </Modal>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .variation-collapse {
    .variation {
      position: relative;

      .variations-delete-btn-wrapper {
        position: absolute;
        right: 6px;
        top: 6px;
      }
    }

    .options-wrapper {
      position: relative;
      margin-top: 10px;

      .options-delete-btn {
        position: absolute;
        top: -7px;
        right: 0;
        height: 30px;
      }
    }
  }
`
