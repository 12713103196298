import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Checkbox, Input } from 'antd'
import { InputNumber } from 'formik-antd'
import React from 'react'
import styled from 'styled-components'

import FormItem from '../../../utility/FormItem'

const CampaignAffiliate = ({ campaign, values, setValues }) => {
  return (
    <Wrapper>
      {!campaign?.brand?.affiliateLink?.length && (
        // Only display if not on internal affiliate program to avoid confusion
        // (internal affiliate program has separate commission system)
        <div className='section-wrapper'>
          <FormItem size='small' label='Affiliate Commission' name='affiliateCommission'>
            <InputNumber name='affiliateCommission' />
          </FormItem>
        </div>
      )}

      {campaign.brand.extraData?.rakutenId && (
        <div className='section-wrapper'>
          <h2>Rakuten</h2>
          {values.rakutenAffiliateLinks.map((affiliateLink, i) => (
            <div key={i} style={{ marginBottom: '16px' }}>
              <FormItem
                size='small'
                label={`Link ${values.rakutenAffiliateLinks.length > 1 ? i + 1 : ''}`}
                subtext={
                  i < 1 &&
                  'Where would you like this link to redirect to on your store? eg. https://my-store.com/my-product'
                }>
                <Input
                  value={affiliateLink.url}
                  onChange={e => {
                    setValues(prev => ({
                      ...prev,
                      rakutenAffiliateLinks: prev.rakutenAffiliateLinks.map((link, j) =>
                        j === i ? { ...link, url: e.target.value } : link
                      ),
                    }))
                  }}
                />
              </FormItem>
              <FormItem
                size='small'
                label='Description'
                subtext={
                  i < 1 && 'Explain to the creators where this link should be posted and by whom'
                }>
                <Input.TextArea
                  value={affiliateLink.description}
                  onChange={e => {
                    setValues(prev => ({
                      ...prev,
                      rakutenAffiliateLinks: prev.rakutenAffiliateLinks.map((link, j) =>
                        j === i ? { ...link, description: e.target.value } : link
                      ),
                    }))
                  }}
                />
              </FormItem>
              {i > 0 && (
                <Button
                  icon={<DeleteOutlined />}
                  type='link'
                  danger
                  onClick={() => {
                    setValues(prev => {
                      prev.rakutenAffiliateLinks.splice(i, 1)
                      return {
                        ...prev,
                        rakutenAffiliateLinks: [...prev.rakutenAffiliateLinks],
                      }
                    })
                  }}>
                  Remove Link {i + 1}
                </Button>
              )}
            </div>
          ))}
          <FormItem>
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                setValues(prev => ({
                  ...prev,
                  rakutenAffiliateLinks: [
                    ...prev.rakutenAffiliateLinks,
                    {
                      url: '',
                      description: '',
                    },
                  ],
                }))
              }}
              type='primary'>
              Add Link
            </Button>
          </FormItem>
        </div>
      )}

      {!!campaign.brand.extraData?.impactRadiusId &&
        campaign?.metaData?.impactRadiusAffiliateLinks && (
          <div className='section-wrapper'>
            <h2>Impact Radius</h2>
            {values.impactRadiusAffiliateLinks.map((affiliateLink, i) => (
              <div key={i} style={{ marginBottom: '16px' }}>
                <FormItem
                  size='small'
                  label={`Link ${values.impactRadiusAffiliateLinks.length > 1 ? i + 1 : ''}`}
                  subtext={
                    i < 1 &&
                    "What website path should the affiliate's links to point to? eg. /shop or /product/basic-t-shirt"
                  }>
                  <Input
                    value={affiliateLink.url}
                    placeholder='eg. ?id=LY2Eq4pKalQ&offerid=1177955.44&type=3&subid=0'
                    onChange={e => {
                      setValues(prev => ({
                        ...prev,
                        impactRadiusAffiliateLinks: prev.impactRadiusAffiliateLinks.map(
                          (link, j) => (j === i ? { ...link, url: e.target.value } : link)
                        ),
                      }))
                    }}
                  />
                </FormItem>
                <FormItem
                  size='small'
                  label='Description'
                  subtext={
                    i < 1 && 'Explain to the creators where this link should be posted and by whom'
                  }>
                  <Input.TextArea
                    value={affiliateLink.description}
                    onChange={e => {
                      setValues(prev => ({
                        ...prev,
                        impactRadiusAffiliateLinks: prev.impactRadiusAffiliateLinks.map(
                          (link, j) => (j === i ? { ...link, description: e.target.value } : link)
                        ),
                      }))
                    }}
                  />
                </FormItem>
                {i > 0 && (
                  <Button
                    icon={<DeleteOutlined />}
                    type='link'
                    danger
                    onClick={() => {
                      setValues(prev => {
                        prev.impactRadiusAffiliateLinks.splice(i, 1)
                        return {
                          ...prev,
                          impactRadiusAffiliateLinks: [...prev.impactRadiusAffiliateLinks],
                        }
                      })
                    }}>
                    Remove Link {i + 1}
                  </Button>
                )}
              </div>
            ))}
            <FormItem>
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  setValues(prev => ({
                    ...prev,
                    impactRadiusAffiliateLinks: [
                      ...prev.impactRadiusAffiliateLinks,
                      {
                        url: '',
                        description: '',
                      },
                    ],
                  }))
                }}
                type='primary'>
                Add Link
              </Button>
            </FormItem>
          </div>
        )}

      {campaign.brand.extraData?.awinId && (
        <div className='section-wrapper'>
          <h2>Awin</h2>
          {values.affiliateLinks.map((affiliateLink, i) => (
            <div key={i} style={{ marginBottom: '16px' }}>
              <FormItem
                size='small'
                label={`Link ${values.affiliateLinks.length > 1 ? i + 1 : ''}`}
                subtext={
                  i < 1 &&
                  "What website path should the affiliate's links to point to? eg. /shop or /product/basic-t-shirt"
                }>
                <Input
                  value={affiliateLink.url}
                  addonBefore={campaign.brand.website}
                  onChange={e => {
                    setValues(prev => ({
                      ...prev,
                      affiliateLinks: prev.affiliateLinks.map((link, j) =>
                        j === i ? { ...link, url: e.target.value } : link
                      ),
                    }))
                  }}
                />
              </FormItem>
              <FormItem
                size='small'
                label='Description'
                subtext={
                  i < 1 && 'Explain to the creators where this link should be posted and by whom'
                }>
                <Input.TextArea
                  value={affiliateLink.description}
                  onChange={e => {
                    setValues(prev => ({
                      ...prev,
                      affiliateLinks: prev.affiliateLinks.map((link, j) =>
                        j === i ? { ...link, description: e.target.value } : link
                      ),
                    }))
                  }}
                />
              </FormItem>
              {i > 0 && (
                <Button
                  icon={<DeleteOutlined />}
                  type='link'
                  danger
                  onClick={() => {
                    setValues(prev => {
                      prev.affiliateLinks.splice(i, 1)
                      return {
                        ...prev,
                        affiliateLinks: [...prev.affiliateLinks],
                      }
                    })
                  }}>
                  Remove Link {i + 1}
                </Button>
              )}
            </div>
          ))}
          <FormItem>
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                setValues(prev => ({
                  ...prev,
                  affiliateLinks: [
                    ...prev.affiliateLinks,
                    {
                      url: '',
                      description: '',
                    },
                  ],
                }))
              }}
              type='primary'>
              Add Link
            </Button>
          </FormItem>
        </div>
      )}

      {!!campaign?.brand?.affiliateLinks?.length && (
        <div className='section-wrapper'>
          <FormItem
            label='Affiliate Links'
            subtext='Select the links that you want to include for this campaign.'>
            <div className='affiliate-selection'>
              {campaign?.brand?.affiliateLinks?.map((link, i) => (
                <Checkbox
                  key={i}
                  className='link-checkbox'
                  checked={values?.brandAffiliateLinkIds?.includes(link.id)}
                  name='brandAffiliateLinkIds'
                  onChange={e => {
                    if (e.target.checked) {
                      setValues({
                        ...values,
                        brandAffiliateLinkIds: [...values.brandAffiliateLinkIds, link.id],
                      })
                    } else {
                      setValues({
                        ...values,
                        brandAffiliateLinkIds: values.brandAffiliateLinkIds.filter(
                          item => item !== link.id
                        ),
                      })
                    }
                  }}>
                  <p className='url'>{link.urlPath}</p>
                  <p className='description'>{link.description}</p>
                </Checkbox>
              ))}
            </div>
          </FormItem>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .section-wrapper {
    max-width: 600px;
    background: #f6f6f6;
    padding: 20px;
    border-radius: 10px;
    margin: 20px 0;
  }

  .affiliate-selection {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 25rem;
    overflow: auto;
    ${props => props.theme.scrollbar}
    margin-top: 4px;
  }
  .link-checkbox {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #e6e6e6;
    p {
      margin: 0;
    }
    .description {
      color: ${props => props.theme.crcoGrey};
    }
  }
`

export default CampaignAffiliate
